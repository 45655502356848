@charset "UTF-8";

@import "mixin";

.m-link_txt {
  color: $color-main;
  &:hover {
    text-decoration: underline;
  }
}

.m-btn {
  a,button {
    display: block;
    padding: 25px 40px;
    font-size: 20px;
    font-weight: $weight-bold;
    line-height: 1.5;
    color: #fff;
    background: $color-main;
    border-radius: 40px;
    text-align: center;
    position: relative;
    border: 1px solid $color-main;
    transition: .15s;
    @include sp {
      font-size: 16px;
      padding: 15px 20px;
    }
    &:after {
      font-family: 'shigotojuku-solid';
      content: '\f138';
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 25px;
      margin-top: -14px;
      transition: .15s;
      @include sp {
        margin-top: -11px;
      }
    }
    &:hover {
      background: #fff;
      color: $color-main;
      cursor: pointer;
    }
  }
  &-free {
    a {
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 40px;
      span {
        display: inline-block;
        color: $color-main;
        background: #fff;
        padding: 0 10px;
        border-radius: 15px;
        transition: .15s;
      }
      &:hover {
        span {
          background: $color-main;
          color: #fff;
        }
      }
    }
  }
  &-pdf {
    a {
      &:after {
        font-family: 'shigotojuku-solid';
        content: '\f1c1';
        right: 20px;
      }
    }
  }
}

.m-input_txt {
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  border: 2px solid #ebebeb;
  border-radius: 3px;
  background-color: #fff;
  @include sp {
    padding: 0 10px;
  }
  &-other {
    width: 500px;
    height: 40px;
    margin-left: 10px;
    @include sp {
      width: 100%;
      margin-top: 5px;
      margin-left: 0;
    }
  }
}
.m-input_txtarea {
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  border: 2px solid #ebebeb;
  border-radius: 3px;
  background-color: #fff;
  @include sp {
    padding: 10px;
  }
}
.m-input_radio {}
.m-select {
  width: 100%;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  padding: 15px 20px;
  border: 2px solid #ebebeb;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
}

.txt-heading {
  text-align: center;
  p {
    font-size: 40px;
    line-height: 2;
    font-weight: $weight-bold;
    display: inline-block;
    @include sp {
      font-size: 22px;
      line-height: 1.7;
      border: 0;
    }
    strong {
      color: $color-main;
      @include sp {
        border-bottom: 3px solid $color-yl;
      }
    }
    .border {
      border-bottom: 5px solid $color-yl;
      @include sp {
        border-bottom: 3px solid $color-yl;
      }
    }
    .larger {
      font-size: 150%;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.ttl-sub {
  font-size: 35px;
  display: inline-block;
  text-align: center;
  @include sp {
    font-size: 22px;
  }
}

.txt-main {
  width: 724px;
  margin: 50px auto 0;
  @include sp {
    width: auto;
    margin-top: 30px;
  }
  p {
    font-size: 20px;
    line-height: 1.7;
    @include sp {
      font-size: 16px;
      line-height: 1.8;
    }
    strong {
      color: $color-main;
    }
  }
  &--attention {
    &:before {
      font-family: 'shigotojuku-solid';
      content: '\f06a';
      display: inline-block;
      margin-right: 3px;
    }
  }
  &--link {
    color: $color-main;
    transition: .15s;
    &:hover {
      text-decoration: underline;
    }
  }
  .attention {
    background: #ddd;
    padding: 20px;
    margin-top: 80px;
    position: relative;
    @include sp {
      margin-top: 30px;
    }
    strong {
      color: #333;
      margin-bottom: 10px;
      display: block;
      margin-bottom: 5px;
    }
    &--apr {
      font-weight: $weight-bold;
    }
    &--note_link {
      font-size: 16px;
      display: block;
      color: $color-main;
      margin-top: 10px;
      @include sp {
        font-size: 14px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.txt-main_emergency {
  color: #f00;
  font-size: 16px;
  text-decoration: underline;
  display: block;
}

.txt-note {
  font-size: 14px;
  display: block;
  line-height: 1.5;
}

.inner {
  width: 1000px;
  margin: 0 auto;
  @include sp {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.l-main {
  display: block;
  padding-top: 99px;
  @include sp {
    padding-top: 70px;
  }
  &-confirm {
    .l-form_box {
      &:nth-child(3),&:nth-child(4) {
        display: none;
      }
    }
  }
}

.l-section {
  padding-bottom: 90px;
  @include sp {
    padding-bottom: 35px;
  }
  &--ttl {
    font-size: 40px;
    line-height: 1.8;
    color: #fff;
    background: $color-main;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    @include sp {
      font-size: 24px;
      line-height: 1.5;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.l-pv {
  width: 100%;
  text-align: center;
  @include sp {
    padding-left: 20px;
    padding-right: 20px;
  }
  &-briefing {
    background-color: $color-yl;
    background-image: url('../images/bg-l-pv-briefing.png');
    background-repeat: no-repeat;
    background-position: center center;
    .l-pv--ttl {
      background: #fff;
      color: #333;
    }
  }
  &--ttl {
    font-size: 40px;
    min-width: 550px;
    display: inline-block;
    margin-top: 95px;
    margin-bottom: 95px;
    padding: 20px 10px;
    background: $color-yl;
    border-radius: 40px;
    @include sp {
      min-width: 280px;
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 8px 15px;
    }
    &-report{
      margin-top: 105px;
      margin-bottom: 155px;
      @include sp {
        margin-bottom: 90px;
      }
    }
  }
}

.l-target_req {
  margin-top: 50px;
  padding-left: 55px;
  padding-right: 55px;
  @include clearfix;
  @include sp {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    position: relative;
  }
  &--pic {
    float: left;
    @include sp {
      width: 30vw;
      height: auto;
      position: absolute;
      left: 10px;
      bottom: 0;
    }
  }
  &--lead {
    font-size: 20px;
    font-weight: $weight-bold;
    line-height: 1.5;
    float: right;
    width: 560px;
    @include sp {
      float: none;
      width: auto;
      font-size: 16px;
    }
    strong {
      border-bottom: 5px solid $color-yl;
    }
  }
  &--lead_age {
    font-size: 30px;
    font-weight: $weight-bold;
    color: $color-main;
    @include sp {
      font-size: 24px;
    }
  }
  &--note {
    float: right;
    width: 560px;
    margin-top: 20px;
    @include sp {
      width: 210px;
      font-size: 10px;
      margin-top: 5px;
    }
  }
}
.l-target_req_list {
  width: 560px;
  float: right;
  margin-top: 40px;
  @include sp {
    width: 210px;
    margin-top: 30px;
  }
  &--item {
    width: 100%;
    font-size: 24px;
    font-weight: $weight-bold;
    line-height: 1.2;
    color: $color-main;
    padding: 20px 90px;
    border: 3px solid $color-yl;
    border-radius: 50px;
    margin-top: 30px;
    position: relative;
    @include sp {
      font-size: 14px;
      padding: 10px 10px 10px 38px;
      border-radius: 20px;
      margin-top: 10px;
    }
    &:before {
      font-size: 50px;
      position: absolute;
      top: 15px;
      left: 20px;
      color: #000;
      @include sp {
        font-size: 24px;
        top: 50%;
        left: 7px;
        margin-top: -12px;
      }
    }
    &:first-child {
      margin-top: 0;
      &:before {
        content: '①';
      }
    }
    &:last-child {
      &:before {
        content: '②';
      }
    }
  }
}

.l-form {
  margin-top: 40px;
  padding: 0 50px;
  @include sp {
    padding: 0 10px;
  }
  &--submit {
    margin-top: 40px;
    p {
      text-align: center;
    }
    .m-btn {
      button {
        width: 300px;
        margin: 20px auto 0;
        @include sp {
          width: 100%;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.l-form_box {
  width: 100%;
  display: table;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  @include sp {
    display: block;
  }
  &-hidden {
    display: none;
  }
  &--heading {
    display: table-cell;
    width: 260px;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 75px;
    vertical-align: middle;
    position: relative;
    @include sp {
      display: block;
      width: 100%;
    }
  }
  &--label {
    font-size: 13px;
    color: #666;
    position: absolute;
    top: 50%;
    right: 30px;
    padding: 2px 7px;
    margin-top: -11px;
    border-radius: 3px;
    background: #eaeaea;
    @include sp {
      right: 0;
    }
    &-required {
      color: #fff;
      background: #d40000;
    }
  }
  &--input {
    display: table-cell;
    vertical-align: middle;
    @include sp {
      display: block;
      margin-top: 10px;
    }
    &-select {
      width: 50%;
      display: inline-block;
      position: relative;
      @include sp {
        width: 100%;
      }
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 2px solid #999;
        border-bottom: 2px solid #999;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -5px;
      }
    }
    &-noclick {
      pointer-events: none;
      &:after {
        display: none;
      }
    }
    &-tri {
      padding: 0 10px;
      @include sp {
        width: 90%;
        padding-left: 0;
        margin-bottom: 10px;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    &-confirm {
      font-size: 16px;
    }
  }
  &--tri_outer {
    display: flex;
    align-items: center;
    @include sp {
      flex-wrap: wrap;
    }
  }
  &--note {
    padding-top: 10px;
    display: block;
  }
  &--error {
    .form-error {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #d40000;
      margin-top: 5px;
      &:before {
        font-family: 'shigotojuku-solid';
        content: '\f06a';
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
}
.l-form_box_list {
  &--item {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  &--check_label {
    position: relative;
    margin-left: 10px;
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border: 2px solid #ebebeb;
      display: inline-block;
      position: absolute;
      top: 1px;
      left: -30px;
      margin-right: 5px;
      line-height: 1;
    }
  }
  .m-input_checkbox {
    opacity: 0;
    &:checked {
      &+.l-form_box_list--check_label {
        &:before {
          content: '\2713';
          color: #fff;
          background: $color-main;
        }
      }
    }
  }
}
.l-form_pp {
  padding-top: 40px;
  @include sp {
    padding-top: 30px;
  }
  &--box {
    height: 200px;
    overflow: scroll;
    padding: 20px 30px;
    border: 2px solid #ebebeb;
    @include sp {
      padding: 10px 20px;
    }
  }
  &--heading {
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 10px;
    @include sp {
      font-size: 18px;
    }
  }
  &--txt {
    line-height: 1.7;
    @include sp {
      font-size: 14px;
    }
  }
  &--check {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 16px;
    @include sp {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  &--checkbox {
    opacity: 0;
    &:checked {
      &+.l-form_pp--label {
        &:before {
          content: '\2713';
          color: #fff;
          background: $color-main;
        }
      }
    }
  }
  &--label {
    position: relative;
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border: 2px solid #ebebeb;
      display: inline-block;
      position: absolute;
      top: 1px;
      left: -30px;
      margin-right: 5px;
    }
  }
  &--error {
    .form-error {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #d40000;
      margin-top: 5px;
      &:before {
        font-family: 'shigotojuku-solid';
        content: '\f06a';
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
}
.l-form_pp_list {
  padding-top: 15px;
  padding-bottom: 15px;
  &--heading {
    font-size: 18px;
    font-weight: $weight-bold;
    line-height: 1.5;
    margin-bottom: 10px;
    @include sp {
      font-size: 16px;
    }
  }
  &--txt {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    @include sp {
      font-size: 14px;
    }
  }
}
.l-form_pp_list_in {
  padding-top: 10px;
  padding-bottom: 10px;
  &--item {
    margin-bottom: 5px;
  }
}
.l-form_nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  &--btn {
    width: 300px;
    button {
      width: 100%;
    }
    &-back {
      @include sp {
        margin-top: 15px;
      }
      button {
        background: #b8b8b8;
        border-color: #b8b8b8;
        &:hover {
          color: #b8b8b8;
          border-color: #b8b8b8;
        }
      }
    }
  }
}

.cta_briefing {
  width: 1000px;
  margin: 0 auto 40px;
  padding: 50px;
  background-color: $color-yl;
  background-image: url('../images/bg-cta-briefing.png');
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 20px;
  @include sp {
    width: calc(100% - 40px);
    background-image: none;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  &:before {
    @include sp {
      content: '';
      width: 140px;
      height: 100px;
      display: block;
      background: url('../images/bg-cta-briefing-sp.png') no-repeat top center;
      background-size: cover;
      margin: 0 auto 10px;
    }
  }
  &--txt {
    font-size: 24px;
    font-weight: $weight-bold;
    line-height: 1.7;
    color: #fff;
    text-align: center;
    @include sp {
      font-size: 16px;
    }
  }
  .m-btn {
    width: 340px;
    margin: 50px auto 0;
    @include sp {
      max-width: 100%;
      margin-top: 15px;
    }
  }
}
.cts {
  width: 1000px;
  margin: 0 auto 40px;
  @include clearfix;
  @include sp {
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }
  &--box {
    float: left;
    width: 480px;
    margin-left: 40px;
    background: $color-main;
    border-radius: 20px;
    padding: 30px;
    @include sp {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-top: 20px;
      padding: 15px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  &--ttl {
    font-size: 24px;
    line-height: 1.7;
    text-align: center;
    color: #fff;
    @include sp {
      font-size: 16px;
      display: inline-block;
      float: left;
      text-align: left;
      padding-top: 30px;
    }
  }
  &--pic {
    display: block;
    margin: 10px auto;
    @include sp {
      float: right;
      width: 45vw;
    }
  }
  &--description {
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    @include sp {
      clear: both;
      margin-top: 15px;
    }
    .m-btn {
      margin-top: 20px;
    }
  }
  &--txt {
    line-height: 1.7;
    @include sp {
      font-size: 14px;
    }
  }
}

.cta_contents {
  width: 1000px;
  display: flex;
  justify-content:space-between;
  margin: 0 auto 60px;
  @include sp {
    width: calc(100% - 40px);
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  &--box{
    width: 480px;
    padding: 40px 30px 30px;
    background: $color-main;
    border-radius: 20px;
    @include sp {
      padding: 15px 20px;
      margin-bottom: 15px;
    }
    &:last-child {
      @include sp {
        margin-bottom: 0;
      }
    }
  }
  &--ttl {
    font-size: 24px;
    font-weight: $weight-bold;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    @include sp {
      font-size: 20px;
    }
  }
  &--img{
    display: block;
    margin: 0 auto 25px;
    @include sp {
      width: 60%;
      margin-bottom: 10px;
    }
  }
  &--description {
    width: 100%;
    padding: 18px;
    background: #fff;
    border-radius: 20px;
    @include sp {
      padding: 15px;
    }
    .m-btn {
      width: 224px;
      margin: 0 auto;
      @include sp {
        width: 100%;
      }
      a {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
  &--txt {
    margin-bottom: 20px;
    @include sp {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.pr-personal {
  position: relative;
  margin-top: 20px;
  @include sp {
    margin-top: 30px;
    padding-bottom: 30px;
  }
  &--pic {
    @include sp {
      width: 34vw;
    }
  }
}
.pr-personal_comment {
  width: 610px;
  border: 3px solid $color-yl;
  position: absolute;
  padding: 20px;
  border-radius: 20px;
  top: 30px;
  right: 40px;
  display: flex;
  flex-wrap: wrap;
  @include sp {
    width: 52vw;
    top: 0;
    right: 10px;
    padding: 10px;
    border-radius: 8px
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 40px 8px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    top: 53px;
    left: -39px;
    z-index: 2;
    @include sp {
      border-right-width: 25px;
      top: 23px;
      left: -25px;
    }
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 46px 11px 0;
    border-color: transparent $color-yl transparent transparent;
    position: absolute;
    top: 50px;
    left: -49px;
    z-index: 1;
    @include sp {
      border-right-width: 31px;
      top: 20px;
      left: -33px;
    }
  }
  &--item {
    position: relative;
    width: 50%;
    height: 50px;
    font-size: 20px;
    font-weight: $weight-bold;
    line-height: 1.3;
    display: flex;
    align-items: center;
    padding-left: 60px;
    margin-top: 20px;
    @include sp {
      font-size: 12px;
      padding-left: 30px;
      width: 100%;
      height: 22px;
      margin-top: 2px;
    }
    &:before {
      content: '';
      width: 50px;
      height: 50px;
      background: url('../images/bg-check-icon.png') no-repeat center center;
      background-size: cover;
      position: absolute;
      left: 0;
      @include sp {
        width: 22px;
        height: 22px;
      }
    }
    &:nth-of-type(1),&:nth-of-type(2) {
      margin-top: 0;
    }
    &:nth-of-type(2) {
      margin-top: 0;
      @include sp {
        margin-top: 5px;
      }
    }
  }
}

.cta_personal {
  width: 100%;
  border-top: 6px solid $color-main;
  @include sp {
    margin-top: 40px;
  }
  .inner {
    padding: 50px 40px 40px;
    background: url('../images/bg-cta-personal.png') no-repeat bottom right 30px;
    @include sp {
      padding: 15px 10px 20px;
      background: none;
    }
  }
  &--lead {
    font-size: 40px;
    font-weight: $weight-bold;
    line-height: 1.5;
    @include sp {
      font-size: 20px;
      text-align: center;
    }
  }
  &--logo {
    width: 435px;
    height: 138px;
    display: block;
    background: url('../images/logo.svg') no-repeat center center;
    background-size: cover;
    @include sp {
      width: 60vw;
      height: 19vw;
      margin: 0 auto;
    }
  }
  .m-btn {
    width: 360px;
    margin-top: 30px;
    margin-left: 35px;
    @include sp {
      width: calc(100% - 20px);
      margin: 10px auto 0;
    }
  }
}

.cta_schedule {
  position: fixed;
  background: $color-yl;
  top: -55px;
  right: 10px;
  font-size: 16px;
  font-weight: $weight-bold;
  line-height: 1.5;
  color: #fff;
  padding: 10px 30px 10px 10px;
  z-index: 9;
  box-shadow: 3px 3px 2px rgba(0,0,0,0.2);
  transition: .15s;
  @include sp {
    font-size: 14px;
    top: -40px;
    left: 10px;
    right: initial;
    padding: 5px 20px 5px 10px;
    width: calc(100% - 20px);
  }
  &:after {
    font-family: 'shigotojuku-solid';
    content: '\f138';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -12px;
    transition: .15s;
    @include sp {
      margin-top: -10px;
    }
  }
  &:hover {
    background: $color-main;
    cursor: pointer;
  }
  &-announce {
    padding-right: 10px;
    &:after {
      display: none;
    }
    &:hover {
      background: $color-yl;
      cursor: auto;
    }
  }
  &-emergency {
    background: transparent;
    padding: 0;
    box-shadow: none;
    @include sp {
      padding-right: 0;
      padding-left: 0;
    }
    &:after {
      content: none;
    }
    &:hover {
      background: transparent;
      opacity: .9;
    }
  }
  &--sub {
    background: $color-main;
    display: inline-block;
    text-align: center;
    padding: 3px 7px;
    margin-right: 10px;
  }
  &--kerning {
    margin-left: -0.4em;
  }
}

// IE
@media all and (-ms-high-contrast:none){
  .txt-main {
    p {
      strong {
        font-weight: 600;
      }
    }
  }
}

@media screen and(max-width:320px) {
  .l-target_req {
    &--pic {
      width: 27vw;
    }
  }
  .l-target_req_list {
    width: 190px;
  }
  .cta_schedule {
    font-size: 12px;
    &:after {
      margin-top: -9px;
    }
  }
}
