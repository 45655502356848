@charset "UTF-8";
.m-link_txt {
  color: #ff8a16;
}

.m-link_txt:hover {
  text-decoration: underline;
}

.m-btn a, .m-btn button {
  display: block;
  padding: 25px 40px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  background: #ff8a16;
  border-radius: 40px;
  text-align: center;
  position: relative;
  border: 1px solid #ff8a16;
  transition: .15s;
}

@media (max-width: 425px) {
  .m-btn a, .m-btn button {
    font-size: 16px;
    padding: 15px 20px;
  }
}

.m-btn a:after, .m-btn button:after {
  font-family: 'shigotojuku-solid';
  content: '\f138';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 25px;
  margin-top: -14px;
  transition: .15s;
}

@media (max-width: 425px) {
  .m-btn a:after, .m-btn button:after {
    margin-top: -11px;
  }
}

.m-btn a:hover, .m-btn button:hover {
  background: #fff;
  color: #ff8a16;
  cursor: pointer;
}

.m-btn-free a {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 40px;
}

.m-btn-free a span {
  display: inline-block;
  color: #ff8a16;
  background: #fff;
  padding: 0 10px;
  border-radius: 15px;
  transition: .15s;
}

.m-btn-free a:hover span {
  background: #ff8a16;
  color: #fff;
}

.m-btn-pdf a:after {
  font-family: 'shigotojuku-solid';
  content: '\f1c1';
  right: 20px;
}

.m-input_txt {
  font-size: 16px;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  border: 2px solid #ebebeb;
  border-radius: 3px;
  background-color: #fff;
}

@media (max-width: 425px) {
  .m-input_txt {
    padding: 0 10px;
  }
}

.m-input_txt-other {
  width: 500px;
  height: 40px;
  margin-left: 10px;
}

@media (max-width: 425px) {
  .m-input_txt-other {
    width: 100%;
    margin-top: 5px;
    margin-left: 0;
  }
}

.m-input_txtarea {
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  border: 2px solid #ebebeb;
  border-radius: 3px;
  background-color: #fff;
}

@media (max-width: 425px) {
  .m-input_txtarea {
    padding: 10px;
  }
}

.m-select {
  width: 100%;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  padding: 15px 20px;
  border: 2px solid #ebebeb;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff;
}

.txt-heading {
  text-align: center;
}

.txt-heading p {
  font-size: 40px;
  line-height: 2;
  font-weight: 700;
  display: inline-block;
}

@media (max-width: 425px) {
  .txt-heading p {
    font-size: 22px;
    line-height: 1.7;
    border: 0;
  }
}

.txt-heading p strong {
  color: #ff8a16;
}

@media (max-width: 425px) {
  .txt-heading p strong {
    border-bottom: 3px solid #f4b800;
  }
}

.txt-heading p .border {
  border-bottom: 5px solid #f4b800;
}

@media (max-width: 425px) {
  .txt-heading p .border {
    border-bottom: 3px solid #f4b800;
  }
}

.txt-heading p .larger {
  font-size: 150%;
  padding-left: 5px;
  padding-right: 5px;
}

.ttl-sub {
  font-size: 35px;
  display: inline-block;
  text-align: center;
}

@media (max-width: 425px) {
  .ttl-sub {
    font-size: 22px;
  }
}

.txt-main {
  width: 724px;
  margin: 50px auto 0;
}

@media (max-width: 425px) {
  .txt-main {
    width: auto;
    margin-top: 30px;
  }
}

.txt-main p {
  font-size: 20px;
  line-height: 1.7;
}

@media (max-width: 425px) {
  .txt-main p {
    font-size: 16px;
    line-height: 1.8;
  }
}

.txt-main p strong {
  color: #ff8a16;
}

.txt-main--attention:before {
  font-family: 'shigotojuku-solid';
  content: '\f06a';
  display: inline-block;
  margin-right: 3px;
}

.txt-main--link {
  color: #ff8a16;
  transition: .15s;
}

.txt-main--link:hover {
  text-decoration: underline;
}

.txt-main .attention {
  background: #ddd;
  padding: 20px;
  margin-top: 80px;
  position: relative;
}

@media (max-width: 425px) {
  .txt-main .attention {
    margin-top: 30px;
  }
}

.txt-main .attention strong {
  color: #333;
  margin-bottom: 10px;
  display: block;
  margin-bottom: 5px;
}

.txt-main .attention--apr {
  font-weight: 700;
}

.txt-main .attention--note_link {
  font-size: 16px;
  display: block;
  color: #ff8a16;
  margin-top: 10px;
}

@media (max-width: 425px) {
  .txt-main .attention--note_link {
    font-size: 14px;
  }
}

.txt-main .attention--note_link:hover {
  text-decoration: underline;
}

.txt-main_emergency {
  color: #f00;
  font-size: 16px;
  text-decoration: underline;
  display: block;
}

.txt-note {
  font-size: 14px;
  display: block;
  line-height: 1.5;
}

.inner {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .inner {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.l-main {
  display: block;
  padding-top: 99px;
}

@media (max-width: 425px) {
  .l-main {
    padding-top: 70px;
  }
}

.l-main-confirm .l-form_box:nth-child(3), .l-main-confirm .l-form_box:nth-child(4) {
  display: none;
}

.l-section {
  padding-bottom: 90px;
}

@media (max-width: 425px) {
  .l-section {
    padding-bottom: 35px;
  }
}

.l-section--ttl {
  font-size: 40px;
  line-height: 1.8;
  color: #fff;
  background: #ff8a16;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 425px) {
  .l-section--ttl {
    font-size: 24px;
    line-height: 1.5;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.l-pv {
  width: 100%;
  text-align: center;
}

@media (max-width: 425px) {
  .l-pv {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.l-pv-briefing {
  background-color: #f4b800;
  background-image: url("../images/bg-l-pv-briefing.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.l-pv-briefing .l-pv--ttl {
  background: #fff;
  color: #333;
}

.l-pv--ttl {
  font-size: 40px;
  min-width: 550px;
  display: inline-block;
  margin-top: 95px;
  margin-bottom: 95px;
  padding: 20px 10px;
  background: #f4b800;
  border-radius: 40px;
}

@media (max-width: 425px) {
  .l-pv--ttl {
    min-width: 280px;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 8px 15px;
  }
}

.l-pv--ttl-report {
  margin-top: 105px;
  margin-bottom: 155px;
}

@media (max-width: 425px) {
  .l-pv--ttl-report {
    margin-bottom: 90px;
  }
}

.l-target_req {
  margin-top: 50px;
  padding-left: 55px;
  padding-right: 55px;
}

.l-target_req:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
  content: '.';
}

@media (max-width: 425px) {
  .l-target_req {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    position: relative;
  }
}

.l-target_req--pic {
  float: left;
}

@media (max-width: 425px) {
  .l-target_req--pic {
    width: 30vw;
    height: auto;
    position: absolute;
    left: 10px;
    bottom: 0;
  }
}

.l-target_req--lead {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  float: right;
  width: 560px;
}

@media (max-width: 425px) {
  .l-target_req--lead {
    float: none;
    width: auto;
    font-size: 16px;
  }
}

.l-target_req--lead strong {
  border-bottom: 5px solid #f4b800;
}

.l-target_req--lead_age {
  font-size: 30px;
  font-weight: 700;
  color: #ff8a16;
}

@media (max-width: 425px) {
  .l-target_req--lead_age {
    font-size: 24px;
  }
}

.l-target_req--note {
  float: right;
  width: 560px;
  margin-top: 20px;
}

@media (max-width: 425px) {
  .l-target_req--note {
    width: 210px;
    font-size: 10px;
    margin-top: 5px;
  }
}

.l-target_req_list {
  width: 560px;
  float: right;
  margin-top: 40px;
}

@media (max-width: 425px) {
  .l-target_req_list {
    width: 210px;
    margin-top: 30px;
  }
}

.l-target_req_list--item {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: #ff8a16;
  padding: 20px 90px;
  border: 3px solid #f4b800;
  border-radius: 50px;
  margin-top: 30px;
  position: relative;
}

@media (max-width: 425px) {
  .l-target_req_list--item {
    font-size: 14px;
    padding: 10px 10px 10px 38px;
    border-radius: 20px;
    margin-top: 10px;
  }
}

.l-target_req_list--item:before {
  font-size: 50px;
  position: absolute;
  top: 15px;
  left: 20px;
  color: #000;
}

@media (max-width: 425px) {
  .l-target_req_list--item:before {
    font-size: 24px;
    top: 50%;
    left: 7px;
    margin-top: -12px;
  }
}

.l-target_req_list--item:first-child {
  margin-top: 0;
}

.l-target_req_list--item:first-child:before {
  content: '①';
}

.l-target_req_list--item:last-child:before {
  content: '②';
}

.l-form {
  margin-top: 40px;
  padding: 0 50px;
}

@media (max-width: 425px) {
  .l-form {
    padding: 0 10px;
  }
}

.l-form--submit {
  margin-top: 40px;
}

.l-form--submit p {
  text-align: center;
}

.l-form--submit .m-btn button {
  width: 300px;
  margin: 20px auto 0;
}

@media (max-width: 425px) {
  .l-form--submit .m-btn button {
    width: 100%;
  }
}

.l-form--submit .m-btn button:hover {
  cursor: pointer;
}

.l-form_box {
  width: 100%;
  display: table;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}

@media (max-width: 425px) {
  .l-form_box {
    display: block;
  }
}

.l-form_box-hidden {
  display: none;
}

.l-form_box--heading {
  display: table-cell;
  width: 260px;
  font-size: 16px;
  line-height: 1.5;
  padding-right: 75px;
  vertical-align: middle;
  position: relative;
}

@media (max-width: 425px) {
  .l-form_box--heading {
    display: block;
    width: 100%;
  }
}

.l-form_box--label {
  font-size: 13px;
  color: #666;
  position: absolute;
  top: 50%;
  right: 30px;
  padding: 2px 7px;
  margin-top: -11px;
  border-radius: 3px;
  background: #eaeaea;
}

@media (max-width: 425px) {
  .l-form_box--label {
    right: 0;
  }
}

.l-form_box--label-required {
  color: #fff;
  background: #d40000;
}

.l-form_box--input {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 425px) {
  .l-form_box--input {
    display: block;
    margin-top: 10px;
  }
}

.l-form_box--input-select {
  width: 50%;
  display: inline-block;
  position: relative;
}

@media (max-width: 425px) {
  .l-form_box--input-select {
    width: 100%;
  }
}

.l-form_box--input-select:after {
  content: '';
  width: 6px;
  height: 6px;
  border-right: 2px solid #999;
  border-bottom: 2px solid #999;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -5px;
}

.l-form_box--input-noclick {
  pointer-events: none;
}

.l-form_box--input-noclick:after {
  display: none;
}

.l-form_box--input-tri {
  padding: 0 10px;
}

@media (max-width: 425px) {
  .l-form_box--input-tri {
    width: 90%;
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.l-form_box--input-tri:first-child {
  padding-left: 0;
}

.l-form_box--input-confirm {
  font-size: 16px;
}

.l-form_box--tri_outer {
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .l-form_box--tri_outer {
    flex-wrap: wrap;
  }
}

.l-form_box--note {
  padding-top: 10px;
  display: block;
}

.l-form_box--error .form-error {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #d40000;
  margin-top: 5px;
}

.l-form_box--error .form-error:before {
  font-family: 'shigotojuku-solid';
  content: '\f06a';
  display: inline-block;
  margin-right: 3px;
}

.l-form_box_list--item {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.l-form_box_list--check_label {
  position: relative;
  margin-left: 10px;
}

.l-form_box_list--check_label:before {
  content: '';
  width: 18px;
  height: 18px;
  border: 2px solid #ebebeb;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: -30px;
  margin-right: 5px;
  line-height: 1;
}

.l-form_box_list .m-input_checkbox {
  opacity: 0;
}

.l-form_box_list .m-input_checkbox:checked + .l-form_box_list--check_label:before {
  content: '\2713';
  color: #fff;
  background: #ff8a16;
}

.l-form_pp {
  padding-top: 40px;
}

@media (max-width: 425px) {
  .l-form_pp {
    padding-top: 30px;
  }
}

.l-form_pp--box {
  height: 200px;
  overflow: scroll;
  padding: 20px 30px;
  border: 2px solid #ebebeb;
}

@media (max-width: 425px) {
  .l-form_pp--box {
    padding: 10px 20px;
  }
}

.l-form_pp--heading {
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .l-form_pp--heading {
    font-size: 18px;
  }
}

.l-form_pp--txt {
  line-height: 1.7;
}

@media (max-width: 425px) {
  .l-form_pp--txt {
    font-size: 14px;
  }
}

.l-form_pp--check {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 425px) {
  .l-form_pp--check {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.l-form_pp--checkbox {
  opacity: 0;
}

.l-form_pp--checkbox:checked + .l-form_pp--label:before {
  content: '\2713';
  color: #fff;
  background: #ff8a16;
}

.l-form_pp--label {
  position: relative;
}

.l-form_pp--label:before {
  content: '';
  width: 18px;
  height: 18px;
  border: 2px solid #ebebeb;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: -30px;
  margin-right: 5px;
}

.l-form_pp--error .form-error {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #d40000;
  margin-top: 5px;
}

.l-form_pp--error .form-error:before {
  font-family: 'shigotojuku-solid';
  content: '\f06a';
  display: inline-block;
  margin-right: 3px;
}

.l-form_pp_list {
  padding-top: 15px;
  padding-bottom: 15px;
}

.l-form_pp_list--heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .l-form_pp_list--heading {
    font-size: 16px;
  }
}

.l-form_pp_list--txt {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media (max-width: 425px) {
  .l-form_pp_list--txt {
    font-size: 14px;
  }
}

.l-form_pp_list_in {
  padding-top: 10px;
  padding-bottom: 10px;
}

.l-form_pp_list_in--item {
  margin-bottom: 5px;
}

.l-form_nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
}

.l-form_nav--btn {
  width: 300px;
}

.l-form_nav--btn button {
  width: 100%;
}

@media (max-width: 425px) {
  .l-form_nav--btn-back {
    margin-top: 15px;
  }
}

.l-form_nav--btn-back button {
  background: #b8b8b8;
  border-color: #b8b8b8;
}

.l-form_nav--btn-back button:hover {
  color: #b8b8b8;
  border-color: #b8b8b8;
}

.cta_briefing {
  width: 1000px;
  margin: 0 auto 40px;
  padding: 50px;
  background-color: #f4b800;
  background-image: url("../images/bg-cta-briefing.png");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 20px;
}

@media (max-width: 425px) {
  .cta_briefing {
    width: calc(100% - 40px);
    background-image: none;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 425px) {
  .cta_briefing:before {
    content: '';
    width: 140px;
    height: 100px;
    display: block;
    background: url("../images/bg-cta-briefing-sp.png") no-repeat top center;
    background-size: cover;
    margin: 0 auto 10px;
  }
}

.cta_briefing--txt {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.7;
  color: #fff;
  text-align: center;
}

@media (max-width: 425px) {
  .cta_briefing--txt {
    font-size: 16px;
  }
}

.cta_briefing .m-btn {
  width: 340px;
  margin: 50px auto 0;
}

@media (max-width: 425px) {
  .cta_briefing .m-btn {
    max-width: 100%;
    margin-top: 15px;
  }
}

.cts {
  width: 1000px;
  margin: 0 auto 40px;
}

.cts:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
  content: '.';
}

@media (max-width: 425px) {
  .cts {
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }
}

.cts--box {
  float: left;
  width: 480px;
  margin-left: 40px;
  background: #ff8a16;
  border-radius: 20px;
  padding: 30px;
}

@media (max-width: 425px) {
  .cts--box {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-top: 20px;
    padding: 15px;
  }
}

.cts--box:first-child {
  margin-left: 0;
}

.cts--ttl {
  font-size: 24px;
  line-height: 1.7;
  text-align: center;
  color: #fff;
}

@media (max-width: 425px) {
  .cts--ttl {
    font-size: 16px;
    display: inline-block;
    float: left;
    text-align: left;
    padding-top: 30px;
  }
}

.cts--pic {
  display: block;
  margin: 10px auto;
}

@media (max-width: 425px) {
  .cts--pic {
    float: right;
    width: 45vw;
  }
}

.cts--description {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
}

@media (max-width: 425px) {
  .cts--description {
    clear: both;
    margin-top: 15px;
  }
}

.cts--description .m-btn {
  margin-top: 20px;
}

.cts--txt {
  line-height: 1.7;
}

@media (max-width: 425px) {
  .cts--txt {
    font-size: 14px;
  }
}

.cta_contents {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 60px;
}

@media (max-width: 425px) {
  .cta_contents {
    width: calc(100% - 40px);
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}

.cta_contents--box {
  width: 480px;
  padding: 40px 30px 30px;
  background: #ff8a16;
  border-radius: 20px;
}

@media (max-width: 425px) {
  .cta_contents--box {
    padding: 15px 20px;
    margin-bottom: 15px;
  }
}

@media (max-width: 425px) {
  .cta_contents--box:last-child {
    margin-bottom: 0;
  }
}

.cta_contents--ttl {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .cta_contents--ttl {
    font-size: 20px;
  }
}

.cta_contents--img {
  display: block;
  margin: 0 auto 25px;
}

@media (max-width: 425px) {
  .cta_contents--img {
    width: 60%;
    margin-bottom: 10px;
  }
}

.cta_contents--description {
  width: 100%;
  padding: 18px;
  background: #fff;
  border-radius: 20px;
}

@media (max-width: 425px) {
  .cta_contents--description {
    padding: 15px;
  }
}

.cta_contents--description .m-btn {
  width: 224px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .cta_contents--description .m-btn {
    width: 100%;
  }
}

.cta_contents--description .m-btn a {
  padding-top: 14px;
  padding-bottom: 14px;
}

.cta_contents--txt {
  margin-bottom: 20px;
}

@media (max-width: 425px) {
  .cta_contents--txt {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.pr-personal {
  position: relative;
  margin-top: 20px;
}

@media (max-width: 425px) {
  .pr-personal {
    margin-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 425px) {
  .pr-personal--pic {
    width: 34vw;
  }
}

.pr-personal_comment {
  width: 610px;
  border: 3px solid #f4b800;
  position: absolute;
  padding: 20px;
  border-radius: 20px;
  top: 30px;
  right: 40px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .pr-personal_comment {
    width: 52vw;
    top: 0;
    right: 10px;
    padding: 10px;
    border-radius: 8px;
  }
}

.pr-personal_comment:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 40px 8px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: 53px;
  left: -39px;
  z-index: 2;
}

@media (max-width: 425px) {
  .pr-personal_comment:before {
    border-right-width: 25px;
    top: 23px;
    left: -25px;
  }
}

.pr-personal_comment:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 46px 11px 0;
  border-color: transparent #f4b800 transparent transparent;
  position: absolute;
  top: 50px;
  left: -49px;
  z-index: 1;
}

@media (max-width: 425px) {
  .pr-personal_comment:after {
    border-right-width: 31px;
    top: 20px;
    left: -33px;
  }
}

.pr-personal_comment--item {
  position: relative;
  width: 50%;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  display: flex;
  align-items: center;
  padding-left: 60px;
  margin-top: 20px;
}

@media (max-width: 425px) {
  .pr-personal_comment--item {
    font-size: 12px;
    padding-left: 30px;
    width: 100%;
    height: 22px;
    margin-top: 2px;
  }
}

.pr-personal_comment--item:before {
  content: '';
  width: 50px;
  height: 50px;
  background: url("../images/bg-check-icon.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  left: 0;
}

@media (max-width: 425px) {
  .pr-personal_comment--item:before {
    width: 22px;
    height: 22px;
  }
}

.pr-personal_comment--item:nth-of-type(1), .pr-personal_comment--item:nth-of-type(2) {
  margin-top: 0;
}

.pr-personal_comment--item:nth-of-type(2) {
  margin-top: 0;
}

@media (max-width: 425px) {
  .pr-personal_comment--item:nth-of-type(2) {
    margin-top: 5px;
  }
}

.cta_personal {
  width: 100%;
  border-top: 6px solid #ff8a16;
}

@media (max-width: 425px) {
  .cta_personal {
    margin-top: 40px;
  }
}

.cta_personal .inner {
  padding: 50px 40px 40px;
  background: url("../images/bg-cta-personal.png") no-repeat bottom right 30px;
}

@media (max-width: 425px) {
  .cta_personal .inner {
    padding: 15px 10px 20px;
    background: none;
  }
}

.cta_personal--lead {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
}

@media (max-width: 425px) {
  .cta_personal--lead {
    font-size: 20px;
    text-align: center;
  }
}

.cta_personal--logo {
  width: 435px;
  height: 138px;
  display: block;
  background: url("../images/logo.svg") no-repeat center center;
  background-size: cover;
}

@media (max-width: 425px) {
  .cta_personal--logo {
    width: 60vw;
    height: 19vw;
    margin: 0 auto;
  }
}

.cta_personal .m-btn {
  width: 360px;
  margin-top: 30px;
  margin-left: 35px;
}

@media (max-width: 425px) {
  .cta_personal .m-btn {
    width: calc(100% - 20px);
    margin: 10px auto 0;
  }
}

.cta_schedule {
  position: fixed;
  background: #f4b800;
  top: -55px;
  right: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  padding: 10px 30px 10px 10px;
  z-index: 9;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  transition: .15s;
}

@media (max-width: 425px) {
  .cta_schedule {
    font-size: 14px;
    top: -40px;
    left: 10px;
    right: initial;
    padding: 5px 20px 5px 10px;
    width: calc(100% - 20px);
  }
}

.cta_schedule:after {
  font-family: 'shigotojuku-solid';
  content: '\f138';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -12px;
  transition: .15s;
}

@media (max-width: 425px) {
  .cta_schedule:after {
    margin-top: -10px;
  }
}

.cta_schedule:hover {
  background: #ff8a16;
  cursor: pointer;
}

.cta_schedule-announce {
  padding-right: 10px;
}

.cta_schedule-announce:after {
  display: none;
}

.cta_schedule-announce:hover {
  background: #f4b800;
  cursor: auto;
}

.cta_schedule-emergency {
  background: transparent;
  padding: 0;
  box-shadow: none;
}

@media (max-width: 425px) {
  .cta_schedule-emergency {
    padding-right: 0;
    padding-left: 0;
  }
}

.cta_schedule-emergency:after {
  content: none;
}

.cta_schedule-emergency:hover {
  background: transparent;
  opacity: .9;
}

.cta_schedule--sub {
  background: #ff8a16;
  display: inline-block;
  text-align: center;
  padding: 3px 7px;
  margin-right: 10px;
}

.cta_schedule--kerning {
  margin-left: -0.4em;
}

@media all and (-ms-high-contrast: none) {
  .txt-main p strong {
    font-weight: 600;
  }
}

@media screen and (max-width: 320px) {
  .l-target_req--pic {
    width: 27vw;
  }
  .l-target_req_list {
    width: 190px;
  }
  .cta_schedule {
    font-size: 12px;
  }
  .cta_schedule:after {
    margin-top: -9px;
  }
}
