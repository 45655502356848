@charset "UTF-8";

// colors
$color-main: #ff8a16;
$color-yl: #f4b800;

// font-weight
$weight-bold: 700;

// media query
@mixin sp {
   @media (max-width: 425px) {
    @content;
  }
}

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}
